import React from "react"
import '../assets/style.css';
import { Row, Col, Container} from 'react-bootstrap';

export default function() {

    const platforms = [
        { imgUrl: "https://assets.sogody.co.uk/sogody/revamp/adobe-target.png" },
        {imgUrl: "https://assets.sogody.co.uk/sogody/revamp/google-optimize.png" }, 
        {imgUrl: "https://assets.sogody.co.uk/sogody/revamp/optimizely.png"},       
        { imgUrl: "https://assets.sogody.co.uk/sogody/revamp/qubit.png"}, 
        { imgUrl: "https://assets.sogody.co.uk/sogody/revamp/vwo.png"},      
    ]

    return (
        <div className="ex-platforms">  
            <Container>  
                <div className="content-box">
                    <p className="title">Helping increase conversion rates through:</p>
                    <p className="subtitle">The expertise stack used to deliver our shared-services</p>
                    <div className="platforms-col">
                        {platforms.map((platforms, index) => ( 
                            <img  style={{ maxWidth: "100%", maxHeight: "40px" }} src={platforms.imgUrl} alt="Logo" key={index} />
                        ))}
                    </div>
                </div>
            </Container>
            </div>

    )
}




