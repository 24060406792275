import React from 'react'
import Layout from "../../components/Layout"
import Updates from '../../components/Updates'
import CaseStudy from '../../components/CaseStudy'
import FAQPO from '../../components/FAQPO'
import WWOPO from '../../components/WWOPO'
import POBoxUnder from "../../components/POBoxUnder";
import BannerPO from '../../components/BannerPO'
import { Seo } from '../../components/Seo'
// import myImg from '../../public/experimentation-og.png'
import Storeez from '../../components/Storeez'
import ExperimentationApproach from '../../components/our-approach/ExperimentationApproach'
import EXPlatforms from '../../components/EXPlatforms'
import { useLocation } from "@reach/router"

export default function() {
    const pathLocation = useLocation();
    const url = pathLocation.href;
    return (
    <Layout>
        {/* image={'https://sogody.com'+myImg} */}
        <Seo 
            title="Experimentation - Sogody" 
            description="We create strategies to test your hypothesis so that we optimize and personalize your platform based on customer data-driven results. The outcome is very high win rates and overall ROI." 
            url={url}
        />
        <div className="personalization-optimisation-page">  
        <BannerPO/>
        {/* <POBoxUnder/>  */}
        {/* <POBox/> */}
        <WWOPO/>
        <ExperimentationApproach/>
        <Storeez/>
        {/* <SpellAndSell/> */}
        <EXPlatforms/>
        <CaseStudy/>
        {/* <FAQPO/> */}
        <Updates/>
        </div>
    </Layout>
    )
}


