import React, { useState } from "react"
import { Row, Col, Container ,Button} from "react-bootstrap"
import banner from '../assets/images/services/experimentation-banner.png';
import ContactUs from "./ContactUs";

import "../assets/style.css";

export default function () {
  const [modalShow, setModalShow] = useState(false);


  return (
    <div className="banner-po single-service-banner">
      <Container>
        <Row>
          <Col xs={12} md={12} lg={6} className="banner">
            <h1 className="name">Experimentation</h1>
            <p className="paragraph">A/B testing to let the data tell you which campaigns and KPIs should be tweaked to increase the usage of your platform, thus leading to improvement in key metrics such as ROI and NPS.</p>
            <Button   
                className="primary-btn-s contact-us-btn"
                onClick={() => setModalShow(true)} 
            > Contact us </Button>
          </Col>
          <Col xs={12} md={12} lg={6} className="banner-img"><img src={banner} alt="Experimentation banner" /></Col>
        </Row>
        <ContactUs  show={modalShow} onHide={() => setModalShow(false)} />
      </Container>  
    </div>
  )
}