import React from "react"
import '../assets/style.css';
import { Row, Col, Container, Button, Jumbotron} from 'react-bootstrap';
import storeez from '../assets/images/services/storeez-logo.svg';
import storeezBanner from '../assets/images/services/storeez-banner.png'

export default function() {

    return (
        <div className="storeez" > 
         <Container>  
             <div className="content-box">
                <Row>
                    <Col xs={12} md={12} lg={6} >
                        <div className="storeez-content">
                            <img src={storeez} className="storeez-logo" alt="Storeez logo"></img>
                            <h1>Strategically evolving with Storeez Studio</h1>
                            <p>Drawing on your knowledge of the market and our knowledge of the online world, we visualise what you can experiment with to get the best experience.</p>
                            <Button href="#" className="storeez-btn">Coming soon</Button>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={6} className="storeez-banner">
                        <img src={storeezBanner} alt="Storeez banner"></img>
                    </Col>
                   
                </Row> 
                </div>

               
            </Container> 
        </div>
    )
}



