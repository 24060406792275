import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import '../assets/style.css';
import { Row, Container, Col, Figure, Button} from 'react-bootstrap';
import WWOPOBOX from "./WWOPOBOX";
import ContactUs from './ContactUs';

export default function() {
    const [modalShow, setModalShow] = React.useState(false);
    const data = useStaticQuery(query);
    const {allContentfulWhatWeOfferInExperimentationService:{
        nodes:wwo
    }} = data

    return (
        <div className="wwo-po">  
            <Container> 
            <Row>
                <div className="wwo-head">
                    <h2 className="wwo-title">What we offer</h2> 
                    <span className="wwo-desc">The complete solution for server-side experimentation</span>
                </div>

                {wwo.map((wwo)=>{
                return <>
                <Col xs={12} md={6} lg={6}>
                    <div className="wwo-content">
                    <p className="title"> 
                        {wwo.title}</p>
                    <p className="description">{wwo.description.description}</p>
                    </div>
                </Col>
                </>
                })}
                <Col xs={12} md={12} lg={12} className="btn-column">
                    <Button className="primary-btn-s po-get-quote" onClick={() => setModalShow(true)} >Get a quote</Button>
                </Col>
                {/* <Col xs={12} md={12} lg={12} className="hr-column">
                    <hr></hr>
                </Col> */}
                {/* <WWOPOBOX/> */}
            </Row> 
            <ContactUs  show={modalShow} onHide={() => setModalShow(false)} />
            </Container></div>
    )
}

export const query = graphql`
{
    allContentfulWhatWeOfferInExperimentationService(sort: {fields: createdAt}, filter: {node_locale: {eq: "en-US"}}) {
        nodes {
        description {
            description
        }
        title
        }
    }
}
`


