import React, {useEffect} from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import '../assets/style.css';
import { Row, Col, Container, Card, Button, Figure} from 'react-bootstrap';
import ContactUs from './ContactUs';

export default function() {
    const data = useStaticQuery(query);
    const {allContentfulUpdates:{
        nodes:updates
    }} = data

    const [modalShow, setModalShow] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [searchResults, setSearchResults] = React.useState([]);

    const handleChange = e => {
        setSearchTerm(e.target.value);
    };
    // console.log(JSON.stringify(data.allContentfulUpdates.nodes));
    // const objKeys = Object.keys(updates); 
    
    // objKeys.forEach(key => { 
    //     let value = updates[key]; 
    
    //     console.log("Value test: "+value.id + " - " +value.upTitle); 
    // });

    // const test = JSON.stringify(data.allContentfulUpdates.nodes);
    // console.log(test);

    React.useEffect(() => {
        const tr = updates.filter(updates=> 
            updates.upTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
            updates.author.toLowerCase().includes(searchTerm.toLowerCase()));

        // const results = Object.keys(updates).filter(update =>
        // update.includes(searchTerm.toLowerCase()) 
        // );
        setSearchResults(tr);
        // console.log("Rezultatet" +results);
    }, [searchTerm]);
    
    return (
        <div className="updates">  
            <Container > 
            <Row>
                <div className="our-updates">
                    <h2 className="title">Latest updates</h2> 
                    <p className="subtitle">The space to share experiences, engage and learn from the Sogody team. Join the conversation by <a  onClick={() => setModalShow(true)}>contacting us</a>.</p>
                </div>
            {/* <Col md="12"> */}
                {/* <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleChange}
                /> */}
                {/* </Col> */}


                {/* {searchResults.slice(0,3).map((updates)=>{  */}
                {updates.slice(0,3).map((updates)=>{
                return <>
                <Col xs={12} md={4} lg={4} className="update-column">
                    <a href={`/updates/${updates.upSlug}/`}> 
                    <Card className={updates.upSlug}   key={updates.id} >
                        <Card.Body>
                            <Image className="update-img" fluid={updates.upImage.fluid} alt="Update img"></Image>
                            <Card.Text className="update-date">{updates.upDate}</Card.Text>
                            <Card.Title><p>{updates.upTitle} </p></Card.Title>
                            {/* <p className="by">By <span className="author">{updates.author}</span> </p> */}
                            <Button href={`/updates/${updates.upSlug}/`} className="tertiary-btn-s">Read more <Figure className="read-more">
                                        <Figure.Image   src={require('../assets/images/next_service.png')}  />
                                    </Figure></Button>
                            {/* <Link to={`/services/${updates.upSlug}`}>
                                Read more
                            </Link>  */}
                    </Card.Body> 
                    </Card> 
                    </a>
                </Col> 
                </> 
                })} 
            </Row> 
            <ContactUs  show={modalShow} onHide={() => setModalShow(false)} />
            </Container></div>
    )
}

export const query = graphql`
{
    allContentfulUpdates(filter: {node_locale: {eq: "en-US"}}, sort: {order: DESC, fields: upDate}, limit: 3) {
        nodes {
            id
            upSlug
            upTitle
            author
            upImage {
                fluid(quality: 100) {
                ...GatsbyContentfulFluid
                }
            }
            upDate(formatString: "DD MMMM YYYY")
        }
    }
}
`


