import React from "react"
import '../../assets/style.css';
import {  Container} from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import ReactReadMoreReadLess from "react-read-more-read-less";

const ExperimentationApproach=() => {

    const settings = {
        slidesPerView: 1.2,
        centeredSlides: true,
        spaceBetween: 20,
        // loop: true,
        noSwiping: false,
        breakpoints: {
          567: {
            centeredSlides: false,
            slidesPerView: 2.5,
            spaceBetween: 30,
            // loop: true
          },
          1200: {
              loop:false,
            centeredSlides: false,
            pagination: false,
            spaceBetween: 35,
            slidesPerView: 3.5,
            noSwiping: false,
            noSwipingClass:"swiper-wrapper",
          }
        }
      };

    const te = [
        {title:"Experimentation", description:"Building high-performing sites with testing built-in across your entire technology stack, to help you learn more and make faster decisions through experimentation.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/ex-1.png" },
        {title:"Measurable",description:"Sogody will create, design, and implement a testing platform that is measurable. Our team develops strategies and test hypotheses so that we can leverage your audience’s behaviors to optimize and personalize your platform.",imgUrl: "https://assets.sogody.co.uk/sogody/revamp/ex-2.png" }, 
        {title:"Testing",description:"We can test your whole website, application, or landing page with other layouts, calls to action, content, and offers. We incorporate modular UI elements that can be modified independently, making the development of the next great test a breeze.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/ex-3.png"},       
        {title:"Optimization",description:"By strategically applying our development and problem- solving skills and offering a flexible and scalable A/B & MVT testing program, we facilitate you on increasing the conversion rates, AOV and CTR with a blend of experience design and data-driven optimization leveraging our testing practice.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/ex-4.png"}, 
        {title:"Analytics",description:"As an extension of your marketing team(s), our job is to help you optimize and personalize your digital platforms for user conversion. Analytics is key to improving results, so we ensure you have the power of data in optimizing every step of the conversion funnel.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/ex-5.png"},       
    ]
    
    return (
        <div className="our-approach-ex">  
            <Container> 
                <div className="te-head">
                    <h1>Our approach</h1> 
                </div>
            <Swiper {...settings}>
            {te.map((t)=>{
                return <SwiperSlide>
                <div className="te-content">
                <div className="sv-desktop">
                        <h2 className="title">{t.title}</h2>
                        <p className="description">{t.description}</p>
                    </div>

                    <div className="sv-mobile">
                        <h2 className="title">{t.title}</h2>
                        <ReactReadMoreReadLess
                            charLimit={120}
                            readMoreText={"Read more"}
                            readLessText={"Hide"}
                        >
                        {t.description}
                        </ReactReadMoreReadLess>
                    </div>
                        <img src={t.imgUrl}></img>
                    </div> 
                    </SwiperSlide>
                })}
                </Swiper>
            </Container>
        </div>
    )
}

export default ExperimentationApproach;